import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"

class IndustriesPlatform extends React.Component {
  render() {
    return (
      <MDBRow className="mt-3">
        <MDBCol md="6 mt-5">
          <GatsbyImage
            image={this.props.data.image.childImageSharp.gatsbyImageData}
            className="image-fluid"
            alt={this.props.data.alttext}
          />
        </MDBCol>

        <MDBCol md="6">
          <div
            className="mt-3 font-w-400 text-medium"
            dangerouslySetInnerHTML={{ __html: this.props.data.description }}
          />
        </MDBCol>
      </MDBRow>
    )
  }
}
export default IndustriesPlatform