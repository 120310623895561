import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import Cta from '../components/cta'
import IndustriesPartners from '../components/industriesPartners'
import IndustriesPlatform from '../components/industriesPlatform'
import IndustriesPlatforms from '../components/industriesPlatforms'
import ColumnAlternatingButton from '../components/columnAlternatingButton'

const IndustriesTemplate =  ({ data }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              <FadeInWhenVisible>
                {post.frontmatter.section.map((sections, index) => (
                  <ColumnAlternatingButton
                    key={index}
                    textCol="7"
                    imageCol="5"
                    headingLevel={2}
                    title={sections.title}
                    subtitle={sections.subtitle}
                    description={sections.description}
                    image={sections.image.childImageSharp.gatsbyImageData}
                    alt={sections.alttext}
                    placement={sections.placement}
                    link={ sections.document ? sections.document.publicURL : sections.link }
                    linktext={sections.linktext}
                  />
                  ))}
              </FadeInWhenVisible>
            </MDBContainer>
          </section>
        ) : (
          ''
        )}
        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {post.frontmatter.solution.title}
                  </h2>
                  <h3>{post.frontmatter.solution.subtitle}</h3>
                  <p className="text-medium">{post.frontmatter.solution.description}</p>
                </MDBCol>
              </MDBRow>

              <IndustriesPlatform data={post.frontmatter.platform} />
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        <section className="bg-gray-light">
          <FadeInWhenVisible>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-5">
                Industry platforms
              </h2>
              <MDBRow>
                {post.frontmatter.industry.map((industries, index) => (
                  <Fragment key={index}>
                    {industries.frontmatter.platformsbyindustry.sort((a, b) => a.frontmatter.sort == b.frontmatter.sort ? 0 : 
                      a.frontmatter.sort < b.frontmatter.sort ? -1 : 1).map((platind, index) => (
                      <Fragment key={index}>
                        {platind.frontmatter.section.map((sections, index) => (
                          <IndustriesPlatforms
                            key={index}
                            image={sections.image}
                            alt={sections.alttext}
                            title={sections.title}
                            features={sections.feature}
                            document={sections.document}
                          />
                        ))}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </MDBRow>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              {post.frontmatter.partners.map((partner, index) => (
                <IndustriesPartners
                  key={index}
                  description={partner.frontmatter.partnerdescription}
                  images={partner.frontmatter.logos}
                  link={partner.frontmatter.link}
                  linktext={partner.frontmatter.linktext}
                />
              ))}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      </Layout>
    </div>
  )
}
export default IndustriesTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query IndustriesTemplate($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        meta {
          title
          description
          keywords
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug        
        section {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          document {
            publicURL
          }
        }
        solution {
          title
          subtitle
          description
        }
        platform {
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          alttext
        }
        servercontent {
          server {
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            alttext
            description
            linktext
            link
          }
        }
        cta {
          title
          description
          linktext
          link
        }
        partners {
          frontmatter {
            partnername
            partnerdescription
            link
            linktext
            logos {
              image {
                childImageSharp {
                  gatsbyImageData(quality: 90) 
                }
              }
              alttext
            }
          }
        }
        industry {
          frontmatter {
            industryname
            platformsbyindustry {
              frontmatter {
                title
                subtitle
                sort
                section {
                  title
                  link
                  linktext
                  document {
                    publicURL
                  }
                  image {
                    childImageSharp {
                      gatsbyImageData(quality: 90) 
                    }
                  }
                  alttext
                  feature {
                    title
                    description
                    datasheetlink
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`