import React, { Fragment } from 'react'
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"

class Card extends React.Component {
  render() {
    return (
      <MDBCol lg="3" md="6" className="pb-4 d-flex pr-md-0">
        <MDBCard className="flex-fill">
          <GatsbyImage
            image={this.props.image.childImageSharp.gatsbyImageData}
            className="m-3"
            alt={this.props.alt}
          />

          <MDBCardBody>
            <div style={{ minHeight: '70px', maxHeight: '70px', }} >
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium pb-4 text-center">
                {this.props.title}
              </h3>
            </div>

            <hr />

            <div className="card-text font-w-400 text-medium mt-2 mb-2">
              {this.props.features.map((feature, index) => (
                <Fragment key={index}>
                  <MDBRow className="pt-4">
                    <MDBCol>
                      <div>
                        <p className="font-w-600 text-medium text-black">
                          {feature.title}
                        </p>
                        <p className="font-w-400 text-medium pb-2">
                          {feature.description}
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Fragment>
              ))}
            </div>
          </MDBCardBody>

          <hr className="mx-3" />
          
          <MDBRow>
                <MDBCol className="mx-3 pt-4 pb-5 text-center">
                  {this.props.document && (
                    <a
                      href={this.props.document.publicURL}
                      className="effect"
                      target="_blank"
                      rel="noopener"
                    >
                      View product datasheet
                    </a>
                  )}
                </MDBCol>
              </MDBRow>
        </MDBCard>
      </MDBCol>
    )
  }
}
export default Card